export const removeAllParamsWhenUTM = (path: string): void => {
  const url = new URL(path)
  const searchParams = url.searchParams

  if (searchParams.toString().includes(`utm_`)) {
    const allParams: string[] = []
    for (const param of searchParams.keys()) {
      allParams.push(param)
    }

    allParams.map((param) => {
      searchParams.delete(param)
    })

    return window.location.replace(url.href)
  }

  return
}
